* {
  margin: 0;
  font-family: "Raleway", sans-serif;
}
#root,
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
}
